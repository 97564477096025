import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { State } from '@core/ngrx/reducers';

import {
    faAlignLeft,
    faClinicMedical,
    faCommentAlt,
    faHandHolding,
    faHandHoldingHeart,
    faHome,
    faIdCard,
    faQuestionCircle,
    faSearch,
    faSignOutAlt,
    faSlidersH,
    faUserCog,
    faMoneyBill,
    faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
    faCreditCard,
    faHandHoldingBox,
    faShoppingCart,
    faHome as faProHome,
    faSearch as faProSearch,
    faHospital,
    faFileAlt,
    faUser,
    faQuestionCircle as faProQuestionCircle,
    faBars,
    faHeart,
    faChevronRight,
    faLayerGroup,
    faUserCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { faUser as faSolidUser, faBriefcaseMedical, faLockAlt, faPaperPlane, faUsers, faNewspaper } from '@fortawesome/pro-solid-svg-icons';

import { Platform } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { CookieService } from 'ngx-cookie-service';
import { authSelectors, userSelectors } from './core/ngrx/selectors';
import { AuthService } from './core/services/auth/auth.service';
import { LoadingService } from './core/services/loading/loading.service';
import { ToolService } from './core/services/tool/tool.service';
import { WorkerService } from './core/services/worker/worker.service';
import { AuthActions, PaymentActions, UserActions, ItineraryActions } from './core/ngrx/actions';
// design system import
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { datadogRum } from '@datadog/browser-rum';

import { defineCustomElements } from '@amoadmin/amo-admin-application-components/loader';
import { isNil } from './shared/utils';
import { UserService } from './core/services/user/user.service';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { fromEvent, interval, Subscription } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { filter, take } from 'rxjs/operators';
import { itinerarySelectors } from '@app/core/ngrx/selectors';
import { ToastService } from './core/services/toast/toast.service';
import { ApplicationService } from './core/services/application/application.service';
import { MutationService } from './core/graphql/mutation/mutation.service';
import awsconfig from '../aws-exports.js';
import Auth from '@aws-amplify/auth';

Auth.configure(awsconfig);

defineCustomElements(window);

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    encapsulation: ViewEncapsulation.Emulated,
})
export class AppComponent implements OnInit {
    showUpdateBanner: boolean;
    constructor(
        public overlay: Overlay,
        private apollo: Apollo,
        private router: Router,
        public route: ActivatedRoute,
        //private swUpdate: SwUpdate,
        private store: Store<State>,
        private platform: Platform,
        private location: Location,
        private cookie: CookieService,
        public toolService: ToolService,
        public workerService: WorkerService,
        private userService: UserService,
        private authService: AuthService,
        public toastService: ToastService,
        public loadingService: LoadingService,
        public viewContainerRef: ViewContainerRef,
        private mutationService: MutationService,
        public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        public applicationService: ApplicationService
    ) {
        angulartics2GoogleTagManager.startTracking();
        this.initializeApp();
        this.content();
        this.hasToken();
        this.oldCookie();
        this.setUserStates();
        // toolService to check for service-worker updates
        this.workerService.checkForUpdates();
        this.workerService.showUpdateBanner.subscribe((data) => {
            console.log('show banner', data);

            this.showUpdateBanner = data;
        });
    }
    public appPages = [
        {
            title: 'Login',
            url: '/login',
            icon: 'log-in',
        },
        {
            title: 'Create Account',
            url: '/signup',
            icon: 'create',
        },
        {
            title: 'Search',
            url: '/search',
            icon: 'search',
        },
    ];

    public privatePages = [
        {
            title: 'My Dashboard',
            url: '/dashboard/',
            icon: 'home',
        },
        {
            title: 'Search Programs',
            url: '/search/',
            icon: 'pulse',
        },
        {
            title: 'My Documents',
            url: '/documents/',
            icon: 'paper',
        },
        {
            title: 'My Profile',
            url: '/profile/',
            icon: 'person',
        },
        {
            title: 'My Settings',
            url: '/settings/',
            icon: 'cog',
        },
    ];

    isLoggedIn = this.store.pipe(select(authSelectors.isAuthenticated));
    showHotLinks = this.store.pipe(select(authSelectors.isHotLinks));

    faAlignLeft = faAlignLeft;
    faCommentAlt = faCommentAlt;
    faSignOutAlt = faSignOutAlt;
    faShoppingCart = faShoppingCart;
    faHandHolding = faHandHolding;
    faIdCard = faIdCard;
    faSearch = faSearch;
    faSlidersH = faSlidersH;
    faHandHoldingHeart = faHandHoldingHeart;
    faQuestionCircle = faQuestionCircle;
    faUserCog = faUserCog;
    faHandHoldingBox = faHandHoldingBox;
    faCreditCard = faCreditCard;
    faClinicMedical = faClinicMedical;
    faHome = faHome;
    faMoneyBill = faMoneyBill;
    faProHome = faProHome;
    faProSearch = faProSearch;
    faHospital = faHospital;
    faFileAlt = faFileAlt;
    faUser = faUser;
    faBars = faBars;
    faHeart = faHeart;
    faChevronRight = faChevronRight;
    faLayerGroup = faLayerGroup;
    faExclamationCircle = faExclamationCircle;
    faUserCircle = faUserCircle;

    profileMenuIcons = {
        faSolidUser: faSolidUser,
        faBriefcaseMedical: faBriefcaseMedical,
        faLockAlt: faLockAlt,
        faPaperPlane: faPaperPlane,
        faUsers: faUsers,
        faNewspaper: faNewspaper,
    };

    isAdmin$ = this.store.pipe(select(userSelectors.isAdmin));
    $validProfile = this.store.select(userSelectors.isValidProfile);
    smallScreen: boolean = window.screen.width < 991;
    enableItineraries: boolean = false;
    censorContent$ = this.store.select(userSelectors.blockContent);
    cookiesDisabled = false;
    userName$ = this.store.select(userSelectors.getUserName);
    userIconName: string;
    userProfileImage$ = this.store.select(userSelectors.getProfileImage);
    userImage: string;

    overlayRef: OverlayRef | null; // right click menu check
    @ViewChild('navMenu') navMenu: TemplateRef<any>; // ng template for right click menu
    sub: Subscription;
    itinerarySelections: any[] = [];
    itineraryList$ = this.store.select(itinerarySelectors.getItineraryList);

    order: string = 'price-asc';

    async ngOnInit() {
        if (!navigator.cookieEnabled) {
            this.cookiesDisabled = true;
        }

        if (window.location.hash) {
            const newUrl = window.location.hash.replace('#', '');
            const url = this.router.createUrlTree([`${newUrl}`]).toString();
            this.location.replaceState(url);
        }

        // if (environment.production) {
        //     interval(1000 * 60 * 0.5).subscribe(() =>
        //         this.swUpdate
        //             .checkForUpdate()
        //             .then(() => console.log('Checking for Updates'))
        //     );
        // }

        // await this.coronaNotification();
        // this.swUpdate.available.subscribe(async res => {
        //     this.swUpdate.activateUpdate().then(function() {
        //         console.log('New version available.');
        //     });
        // });

        this.userName$.subscribe((name: string) => {
            this.userIconName = name
                .split(' ')
                .slice(0, 2)
                .map((l) => l[0])
                .join('');
        });

        this.userProfileImage$.subscribe((image: string) => {
            this.userImage = image;
        });
    }

    initializeApp() {
        this.platform.ready().then(() => {});
    }

    oldCookie() {
        if (this.cookie.check('amotoken')) {
            this.cookie.delete('amotoken');
            window.location.reload();
        }
    }

    hasToken() {
        this.userService.identifyAuthenticatedUser().subscribe(
            (res: any) => {
                if (res.isAuthenticated === true) {
                    //   this.cookie.delete('_temp_');

                    this.store.dispatch(
                        AuthActions.setIsAuthenticated({
                            is_authenticated: res.isAuthenticated,
                        })
                    );
                } else {
                    // this.cookie.set('_temp_', '', 30, '/', '.amopportunities.org', true, 'Lax');
                    // this.cookie.delete('amoactkn');

                    this.store.dispatch(
                        AuthActions.setIsAuthenticated({
                            is_authenticated: res.isAuthenticated,
                        })
                    );
                }
            },
            (err: any) => {
                this.store.dispatch(
                    AuthActions.setIsAuthenticated({
                        is_authenticated: false,
                    })
                );
            }
        );
    }

    logout() {
        this.authService.logout();
    }

    content() {
        this.toolService.content.subscribe((content: any) => {
            // console.log(content);
        });
    }

    closeTab() {
        try {
            if (window.opener) {
                window.close();
            } else {
                window.location.replace('https://admin.amopportunities.org/login');
            }
        } catch (error) {
            window.location.replace('https://admin.amopportunities.org/login');
        }
    }

    /**
     *
     * @param event mouse event, use coordinates to build template on
     * @param selectedTab string value of selected tab
     */
    open({ x, y }: MouseEvent, selectedTab: string) {
        // this try catch for event might not work later
        // need another way to add to params for event
        try {
            event.preventDefault();
        } catch (err) {
            throw err;
        }

        this.close();
        // setup for context menu template
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo({ x, y })
            .withPositions([
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ]);

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.close(),
        });
        // use ng template reference and attach tab string to it
        this.overlayRef.attach(
            new TemplatePortal(this.navMenu, this.viewContainerRef, {
                $implicit: selectedTab,
            })
        );

        this.sub = fromEvent<MouseEvent>(document, 'click')
            .pipe(
                filter((event) => {
                    const clickTarget = event.target as HTMLElement;
                    return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
                }),
                take(1)
            )
            .subscribe(() => this.close());
    }

    /**
     * closes context menu
     */
    close() {
        this.sub && this.sub.unsubscribe();
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }

    /**
     *
     * @param tab sting value for tab selected by right click context menu
     *
     */
    openNewTab(tab: string) {
        const url = window.location.origin;
        window.open(`${url}/${tab}`, '_blank');
        this.close();
    }

    setUserStates() {
        // when user has unclaimed invite code proccess the request
        this.store.select(userSelectors.getUnclaimedInviteExists).subscribe((unclaimedInvite: boolean) => {
            // unclaimed invite exists when true
            if (unclaimedInvite) {
                // when unclaimed invite exists start processing
                this.mutationService
                    .processAffiliateInvite()
                    .toPromise()
                    .then((inviteResponse: any) => {})
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });

        // set all initial states for a user here
        // this check is sent after we know a cookie is set to the window
        // with a valid name
        var itinerarySelections = [];

        this.isLoggedIn.subscribe((auth: boolean) => {
            if (auth) {
                // we know the user has been given the log in state
                // this.cookie.check('mgmt_token')
                return this.apollo
                    .query({
                        query: gql`
                            query usrAuthG($noUser: Boolean!) {
                                user @skip(if: $noUser) {
                                    id
                                    email
                                    phone
                                    city
                                    state
                                    country
                                    postalcode
                                    gender
                                    birthday
                                    role
                                    name
                                    profileComplete
                                    termsagreed
                                    checkdepartment {
                                        id
                                        amoservices
                                    }
                                    stripecustomerid
                                    unclaimedInviteCode
                                    profileimage
                                }
                                management @include(if: $noUser) {
                                    id
                                    email
                                    role
                                    name
                                    type
                                    rules
                                    checkdepartment {
                                        id
                                        amoservices
                                    }
                                }
                            }
                        `,
                        variables: {
                            noUser: this.cookie.check('mgmt_token') || false, // check is mfa for management was successful
                        },
                        fetchPolicy: 'network-only',
                    })
                    .subscribe((response: any) => {
                        // if we find a user
                        if (response.data.user) {
                            datadogRum.addRumGlobalContext('usr', {
                                id: response.data.user.id,
                                email: response.data.user.email,
                                name: response.data.user.name,
                                isManager: false,
                            });

                            // Add email to dataLayer for enhanced ads conversion tracking
                            this.angulartics2GoogleTagManager.pushLayer({
                                cdActivityId: response.data.user.id,
                                cdEmail: response.data.user.email,
                                cdRole: response.data.user.role,
                                cdFirstName: response.data.user.name.split(' ').slice(0, -1).join(' '),
                                cdLastName: response.data.user.name.split(' ').slice(-1).join(' '),
                                cdPhone: response.data.user.phone,
                                cdGender: response.data.user.gender,
                                cdCountry: response.data.user.country,
                                cdCity: response.data.user.city,
                            });

                            // malek hubspot code
                            const _hsq = (window._hsq = window._hsq || []);

                            _hsq.push([
                                'identify',
                                {
                                    email: response.data.user.email,
                                },
                            ]);

                            // set customer id
                            this.store.dispatch(
                                PaymentActions.setStripeCustomerID({
                                    customerID: response.data.user.stripecustomerid,
                                })
                            );

                            // set unclaimed invite code, true when invite record is unclaimed
                            this.store.dispatch(
                                UserActions.setUnclaimedInvite({
                                    unclaimedInviteCode: response.data.user.unclaimedInviteCode,
                                })
                            );

                            // set valid profile
                            this.store.dispatch(
                                UserActions.setValidProfile({
                                    isValidProfile: response.data.user.profileComplete.valid,
                                })
                            );

                            // set profile image
                            this.store.dispatch(
                                UserActions.setProfileImage({
                                    profileImage: response.data.user.profileimage,
                                })
                            );

                            // set user with admin permissions
                            this.store.dispatch(
                                UserActions.setIsAdmin({
                                    isAdmin: !response.data.user.role ? false : response.data.user.role.toLowerCase() === 'admin',
                                })
                            );

                            //get the itinerary list from local storage
                            // var itineraryList = localStorage.getItem(
                            //     response.data.user.id + '-itinerarySelections'
                            // );
                            // if (itineraryList) {
                            //     this.itinerarySelections = JSON.parse(
                            //         itineraryList
                            //     );
                            //     this.store.dispatch(
                            //         ItineraryActions.setItineraryList({
                            //             itineraryList: JSON.parse(
                            //                 itineraryList
                            //             ),
                            //         })
                            //     );
                            // } else {
                            this.applicationService
                                .usersCurrentItinerary({
                                    userid: response.data.user.id,
                                })
                                .subscribe(
                                    (itineraryResponse: any) => {
                                        itinerarySelections = itineraryResponse.selections;
                                        this.store.dispatch(
                                            ItineraryActions.setItineraryList({
                                                itineraryList: itineraryResponse.selections,
                                            })
                                        );
                                        if (itinerarySelections.length > 0) {
                                            this.enableItineraries = true;
                                        } else {
                                        }
                                        localStorage.setItem(response.data.user.id + '-itinerarySelections', JSON.stringify(itinerarySelections));
                                    },
                                    (err: any) => {
                                        console.log(err);
                                    }
                                );
                            //}

                            this.store.dispatch(
                                UserActions.setUserEmail({
                                    userEmail: response.data.user.email,
                                })
                            );

                            this.store.dispatch(
                                UserActions.setUserName({
                                    userName: response.data.user.name,
                                })
                            );

                            this.store.dispatch(
                                UserActions.setUserId({
                                    user_id: response.data.user.id,
                                })
                            );

                            if (response.data.user.checkdepartment) {
                                const isUnmanaged = response.data.user.checkdepartment.amoservices === 'UNMANAGED' ? true : false;
                                // set block content for manage
                                this.store.dispatch(
                                    UserActions.setBlockContent({
                                        isBlockContent: isUnmanaged,
                                    })
                                );

                                if (response.data.user.checkdepartment.id) {
                                    // set user has department
                                    this.store.dispatch(
                                        UserActions.setDepartmentExists({
                                            departmentExists: true,
                                        })
                                    );
                                    // set user department id
                                    this.store.dispatch(
                                        UserActions.setDepartmentId({
                                            department_id: response.data.user.checkdepartment.id,
                                        })
                                    );
                                }
                            } else {
                                this.store.dispatch(
                                    UserActions.setBlockContent({
                                        isBlockContent: false,
                                    })
                                );
                            }
                        } else if (response.data.management) {
                            // set manager cookie
                            this.cookie.set('mgmt_token', 'amo_management', 0, '/', '.amopportunities.org', true, 'Strict');

                            // if we find a manager
                            if (this.cookie.check('_bp_')) {
                                this.cookie.delete('_bp_', '/', 'amopportunities.org');
                            }

                            this.store.dispatch(
                                UserActions.setIsAdmin({
                                    isAdmin: response.data.management.type === 'AMOEMPLOYEE' ? true : false,
                                })
                            );

                            datadogRum.addRumGlobalContext('mgt', {
                                id: response.data.management.id,
                                email: response.data.management.email,
                                name: response.data.management.name,
                                isManager: true,
                            });

                            // check if checkdepartment is not null
                            if (response.data.management.checkdepartment) {
                                const isUnmanaged = !response.data.management.checkdepartment.amoservices
                                    ? false
                                    : response.data.management.checkdepartment.amoservices === 'UNMANAGED'
                                    ? true
                                    : false;

                                this.store.dispatch(
                                    UserActions.setBlockContent({
                                        isBlockContent: isUnmanaged,
                                    })
                                );
                            }

                            if (!isNil(response.data.management.rules)) {
                                const hidePrices = response.data.management.rules.permissions.includes('program:hideprices');
                                this.store.dispatch(
                                    UserActions.setHideProgramPrices({
                                        isHideProgramPrices: hidePrices,
                                    })
                                );
                            }
                            // block nav bar if manager is using permalink
                            if (this.route.snapshot.queryParams.mgmttype) {
                                if (this.route.snapshot.queryParams.mgmttype === 'permalink') {
                                    this.store.dispatch(
                                        UserActions.setValidProfile({
                                            isValidProfile: false,
                                        })
                                    );
                                }
                            }

                            // exception for amo employees
                            if (response.data.management.type) {
                                this.store.dispatch(
                                    UserActions.setBlockContent({
                                        isBlockContent: response.data.management.type === 'AMOEMPLOYEE' ? false : true,
                                    })
                                );

                                // check if user is of management role
                                if (response.data.management.role) {
                                    this.store.dispatch(
                                        UserActions.setIsManager({
                                            isManager:
                                                response.data.management.role !== null ? response.data.management.role.toLowerCase() === 'management' : false,
                                        })
                                    );
                                } else {
                                    this.store.dispatch(
                                        UserActions.setIsManager({
                                            isManager: false,
                                        })
                                    );
                                }
                            }
                        }
                    });
            }
        });
    }

    getwebsiteUpdates() {
        this.workerService.clearShowBanner();
        this.workerService.reload();
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHander(event) {
        if (this.cookie.check('_lnk_prgm_bk_')) {
            this.cookie.delete('_lnk_prgm_bk_', '/', 'amopportunities.org');
        }
    }

    // async coronaNotification() {
    //   if (!this.cookie.check('corona2')) {
    //     const coronaToast = await this.toastController.create({
    //       message: `Please view AMO's COVID-19 resources page for more information including policies related to reserved and future clinical experiences.`,
    //       buttons: [
    //         {
    //           text: 'open',
    //           handler: () => {
    //             this.cookie.set('corona2', 'true', 0, '/', 'amopportunities.org', true, 'Strict');
    //             window.open('https://landing.amopportunities.org/covid-19-resources', '_blank');
    //             coronaToast.dismiss().then().catch();
    //           }
    //         },
    //         {
    //           text: 'close',
    //           handler: () => {
    //             this.cookie.set('corona2', 'true', 0, '/', 'amopportunities.org', true, 'Strict');
    //             coronaToast.dismiss().then().catch();
    //           }
    //         }
    //       ],
    //       color: 'secondary',
    //       position: 'top'
    //     });

    //     await coronaToast.present();
    //   }
    // }
}
