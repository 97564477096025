import { Component, Input, OnInit } from '@angular/core';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-checklist-enrollment',
    templateUrl: './checklist-enrollment.component.html',
    styleUrls: ['./checklist-enrollment.component.scss'],
})
export class ApplicationPreChecklistEnrollmentComponent implements OnInit {
    constructor(private modalCtrl: ModalController) {}

    faExclamationCircle = faExclamationCircle;

    @Input() checklistMissing: any[];
    @Input() checklistComplete: any[];

    // always keep fn at bottom of definitions
    trackByIndex = (index: number): number => index;

    ngOnInit() {}

    dismiss() {
        this.modalCtrl.dismiss();
    }
}
