import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-documents-sidebar',
  templateUrl: './documents-sidebar.component.html',
  styleUrls: ['./documents-sidebar.component.scss'],
})
export class SidebarDocumentsComponent implements OnInit {

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  dismiss() { this.modalCtrl.dismiss(); }

}
