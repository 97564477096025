import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-program-info-modal',
    templateUrl: './program-info-modal.component.html',
    styleUrls: ['./program-info-modal.component.scss'],
})
export class ProgramInfoModalComponent implements OnChanges {
    @Input() program: any;
    @Input() user: any;

    constructor(private modalCtrl: ModalController) {}

    ngOnChanges() {}

    dismiss() {
        this.modalCtrl.dismiss({
            result: false,
        });
    }
}
