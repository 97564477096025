import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-sidebar-alert',
  templateUrl: './sidebar-alert.component.html',
  styleUrls: ['./sidebar-alert.component.scss'],
})
export class SidebarAlertComponent implements OnInit {
  @Input() header: string;
  @Input() title: string;
  @Input() topMessage: string;
  @Input() bottomMessage: string;
  @Input() icon: string;
  @Input() color: string;
  @Input() lines: number;
  @Input() task: string;


  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController
  ) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }

  toSignUp = (): void => {
    this.navCtrl.navigateRoot(['/signup']);
    this.modalCtrl.dismiss();
  }

  toLogin = (): void => {
    this.navCtrl.navigateRoot(['/login']);
    this.modalCtrl.dismiss();
  }
}
