import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ToolService } from '@app/core/services/tool/tool.service';
import { ModalService } from '@core/services/modal/modal.service';

@Component({
    selector: 'app-application-navigator',
    templateUrl: './application-navigator.component.html',
    styleUrls: ['./application-navigator.component.scss'],
})
export class SidebarApplicationNavigatorComponent implements OnInit, OnChanges {
    constructor(
        private toolService: ToolService,
        private modalService: ModalService
    ) {
        this.toolService.content.subscribe(
            (textcontent: any) => (this.textcontent = textcontent)
        );
    }

    textcontent: any;
    data = {
        title: '',
        header: '',
        topMessage: '',
        bottomMessage: '',
        icon: '',
        color: '',
        lines: 2,
        class: 'sidebar-modal-sm',
    };
    @Input() application?: any;

    ngOnInit() {}

    ngOnChanges() {}

    openBookingInfo = () => {
        this.data.topMessage = this.textcontent.shared.sidebarApplicationInfo.modalContent.topContent;
        this.data.bottomMessage = `
      <b>
        ${this.textcontent.shared.sidebarApplicationInfo.modalContent.bottomContent.firstStep}
      </b>
      ${this.textcontent.shared.sidebarApplicationInfo.modalContent.bottomContent.firstStepContent}
      <br>
      <b>
        ${this.textcontent.shared.sidebarApplicationInfo.modalContent.bottomContent.secondStep}
      </b>
      ${this.textcontent.shared.sidebarApplicationInfo.modalContent.bottomContent.secondStepContent}
      `;
        this.modalService.basicInfoAlert(this.data).then((response: any) => {});
    };
}
