<div>
    <!-- component container -->
    <div
        *ngIf="
            !amo?.ashley &&
            !amo?.wanh &&
            !amo?.mia &&
            !amo?.kelli &&
            !amo?.brook &&
            !amo?.megha &&
            !amo?.justin &&
            !amo?.chris &&
            !amo?.grace &&
            !amo?.unassignedcoach
        "
    >
        <!-- Sam -->
        <div class="sidebar-title-container">
            <ion-label class="sidebar-title"
                >{{
                    (toolService.content | async)?.shared?.generalSidebar
                        ?.advisor?.sectiontitle
                }}
            </ion-label>
        </div>
        <div class="no-color-container ion-text-wrap">
            <ion-item>
                <ion-label>
                    <div class="sidebar-subtitle ion-text-wrap">
                        {{
                            (toolService.content | async)?.shared
                                ?.generalSidebar?.advisor?.contactadvisorfirst
                        }}
                        <a
                            href="mailto:{{
                                (toolService.content | async)?.basic?.email
                                    ?.advisors
                            }}"
                            >{{
                                (toolService.content | async)?.basic?.email
                                    ?.advisors
                            }}</a
                        >
                        {{
                            (toolService.content | async)?.shared
                                ?.generalSidebar?.advisor?.contactadvisorsecond
                        }}<a
                            href="{{
                                (toolService.content | async)?.shared
                                    ?.generalSidebar?.advisor
                                    ?.contactadvisorsecondlink
                            }}?email={{ USER_EMAIL }}"
                            target="_blank"
                            >{{
                                (toolService.content | async)?.shared
                                    ?.generalSidebar?.advisor
                                    ?.contactadvisorsecondlinkcta
                            }}
                        </a>
                    </div>
                    <!-- <div class="text-container">
            <ion-text color="text">
              <div class="sidebar-text">
                {{(toolService.content | async)?.shared?.generalSidebar?.advisor?.whatsapptitle}}<br />
                <ion-icon name="logo-whatsapp" style="color: #25D366"></ion-icon>&nbsp;
                <div class="bold-text inline"><a
                    href="https://api.whatsapp.com/send?phone={{(toolService.content | async)?.basic?.whatsapp?.sam}}&text=Hello"
                    target="_blank" rel="noopener noreferrer">{{(toolService.content | async)?.basic?.whatsapp.sam}}</a>
                </div>
              </div>
            </ion-text>
          </div> -->
                </ion-label>
            </ion-item>

            <ion-item
                *ngFor="
                    let advisor of textcontent?.shared?.generalSidebar
                        ?.advisorArray
                "
            >
                <ion-avatar slot="start" class="coach-image">
                    <img src="{{ advisor.image }}" />
                </ion-avatar>
                <div class="sidebar-text">
                    Advisor {{ advisor.name }}: <br />
                    <ion-icon
                        *ngIf="advisor.phone"
                        name="logo-whatsapp"
                        style="color: #25D366"
                    ></ion-icon>
                    <div *ngIf="advisor.phone" class="bold-text inline">
                        <a
                            href="https://api.whatsapp.com/send?phone={{
                                advisor.phone
                            }}&text=Hello"
                            target="_blank"
                            rel="noopener noreferrer"
                            >{{ advisor.phone }}</a
                        >
                    </div>
                    <div class="bold-text inline">
                        <a href="mailto:advisors@amopportunities.org"
                            >Email {{ advisor.name }}</a
                        >
                    </div>
                </div>
            </ion-item>
            <!-- <ion-item>
        <ion-avatar slot="start" class="coach-image">
          <img src="/img/SamSales.png">
        </ion-avatar>
        <div class="sidebar-text">
          Advisor Sam: <br />
          <ion-icon name="logo-whatsapp" style="color: #25D366"></ion-icon>&nbsp;
          <div class="bold-text inline"><a
              href="https://api.whatsapp.com/send?phone={{(toolService.content | async)?.basic?.whatsapp?.sam}}&text=Hello"
              target="_blank" rel="noopener noreferrer">{{(toolService.content | async)?.basic?.whatsapp.sam}}</a>
          </div>
        </div>
      </ion-item>
      <ion-item>
        <ion-avatar slot="start" class="coach-image">
          <img src="/img/Advisor_Ryan.png">
        </ion-avatar>
        <div class="sidebar-text">
          Advisor Ryan: <br />
          <ion-icon name="logo-whatsapp" style="color: #25D366"></ion-icon>&nbsp;
          <div class="bold-text inline"><a
              href="https://api.whatsapp.com/send?phone={{(toolService.content | async)?.basic?.whatsapp?.ryan}}&text=Hello"
              target="_blank" rel="noopener noreferrer">{{(toolService.content | async)?.basic?.whatsapp.ryan}}</a>
          </div>
        </div>
      </ion-item> -->
            <div class="text-container">
                <!-- <div class="sidebar-text"><br />{{(toolService.content | async)?.shared?.generalSidebar?.advisor?.emailtitle}}
        </div>
        <div class="flex">
          <ion-icon name="mail" class="fa-line-padding"></ion-icon> <a href="mailto:AdvisorSam@amopportunities.org"
            class="link-anchor">{{(toolService.content | async)?.basic?.email?.advisorSam}}</a>
        </div> -->
                <br /><br />
                <div class="flex">
                    <fa-icon [icon]="icons.calendar"></fa-icon>&nbsp;<a
                        class="link-anchor bold-text"
                        tappable
                        (click)="openInformationModal()"
                        >{{
                            (toolService.content | async)?.shared
                                ?.generalSidebar?.advisor?.availability
                        }}</a
                    >
                </div>
                <div class="ion-margin-top">
                    <div class="flex">
                        <a
                            href="{{
                                (toolService.content | async)?.basic?.url
                                    ?.roundrobinlink
                            }}?email={{ USER_EMAIL }}"
                            target="_blank"
                            class="link-anchor"
                            >{{
                                (toolService.content | async)?.shared
                                    ?.generalSidebar?.advisor?.roundrobin
                            }}</a
                        >
                    </div>
                </div>
                <div class="ion-margin-top">
                    <div class="flex">
                        <span class="wsApp">Contact us on WhatsApp:&nbsp; +13122410640</span>
                    </div>
                </div>
                <br />&nbsp;
            </div>
        </div>
    </div>
    <!-- Unassigned Coach-->
    <div class="sidebar-title-container" *ngIf="amo?.unassignedcoach">
        <ion-label class="sidebar-title"
            >{{
                (toolService.content | async)?.shared?.generalSidebar?.advisor
                    ?.unassignedcoach
            }}
        </ion-label>
    </div>
    <div class="no-color-container" *ngIf="amo?.unassignedcoach">
        <ion-item>
            <ion-text color="text">
                <div class="sidebar-text">
                    {{
                        (toolService.content | async)?.shared?.generalSidebar
                            ?.advisor?.settingupcoach
                    }}
                    <ion-icon name="mail" class="fa-line-padding"></ion-icon>
                    <a
                        href="mailto:{{
                            (toolService.content | async)?.basic?.email?.success
                        }}"
                        class="link-anchor"
                        >{{
                            (toolService.content | async)?.basic?.email?.success
                        }}</a
                    >
                </div>
            </ion-text>
        </ion-item>
    </div>
    <!-- Ashley-->
    <div
        class="sidebar-title-container"
        *ngIf="amo?.wanh || amo?.ashley || amo?.mia"
    >
        <ion-label class="sidebar-title"
            >{{
                (toolService.content | async)?.shared?.generalSidebar?.advisor
                    ?.meet
            }}
        </ion-label>
    </div>
    <div class="no-color-container" *ngIf="amo?.ashley">
        <ion-item>
            <ion-avatar slot="start" class="coach-image">
                <img src="/img/AshleyCoach.png" />
            </ion-avatar>
            <ion-label>
                <div class="sidebar-subtitle bold">
                    {{
                        (toolService.content | async)?.shared?.generalSidebar
                            ?.advisor?.ashleyintro
                    }}
                </div>
                <div class="text-container">
                    <ion-text color="text">
                        <div class="sidebar-text">
                            {{
                                (toolService.content | async)?.shared
                                    ?.generalSidebar?.advisor?.advisormessage
                            }}
                        </div>
                    </ion-text>
                </div>
            </ion-label>
        </ion-item>
        <div class="text-container">
            <div class="sidebar-text">
                {{
                    (toolService.content | async)?.shared?.generalSidebar
                        ?.advisor?.emailme
                }}
            </div>
            <div class="flex">
                <ion-icon
                    slot="start"
                    name="mail"
                    class="fa-line-padding"
                ></ion-icon>
                <a
                    href="mailto:{{
                        (toolService.content | async)?.basic?.email?.coachAshley
                    }}"
                    class="link-anchor"
                    >{{
                        (toolService.content | async)?.basic?.email?.coachAshley
                    }}</a
                >
            </div>
        </div>
    </div>
    <!-- Wanh -->
    <div class="no-color-container" *ngIf="amo?.wanh">
        <ion-item>
            <ion-avatar slot="start" class="coach-image">
                <img src="/img/WanhCoach.png" />
            </ion-avatar>
            <ion-label>
                <div class="sidebar-subtitle bold">
                    {{
                        (toolService.content | async)?.shared?.generalSidebar
                            ?.advisor?.wanhintro
                    }}
                </div>
                <div class="text-container">
                    <ion-text color="text">
                        <div class="sidebar-text">
                            {{
                                (toolService.content | async)?.shared
                                    ?.generalSidebar?.advisor?.advisormessage
                            }}
                        </div>
                    </ion-text>
                </div>
            </ion-label>
        </ion-item>
        <div class="text-container">
            <div class="sidebar-text">Email me at:</div>
            <div class="flex">
                <ion-icon
                    slot="start"
                    name="mail"
                    class="fa-line-padding"
                ></ion-icon>
                <a
                    href="mailto:{{
                        (toolService.content | async)?.basic?.email?.coachWanh
                    }}"
                    class="link-anchor"
                    >{{
                        (toolService.content | async)?.basic?.email?.coachWanh
                    }}</a
                >
            </div>
        </div>
    </div>
    <!-- Mia -->
    <div class="no-color-container" *ngIf="amo?.mia">
        <ion-item>
            <ion-avatar slot="start" class="coach-image">
                <img src="/img/MiaCoach.png" />
            </ion-avatar>
            <ion-label>
                <div class="sidebar-subtitle bold">
                    {{
                        (toolService.content | async)?.shared?.generalSidebar
                            ?.advisor?.miaintro
                    }}
                </div>
                <div class="text-container">
                    <ion-text color="text">
                        <div class="sidebar-text">
                            {{
                                (toolService.content | async)?.shared
                                    ?.generalSidebar?.advisor?.advisormessage
                            }}
                        </div>
                    </ion-text>
                </div>
            </ion-label>
        </ion-item>
        <div class="text-container">
            <div class="sidebar-text">Email me at:</div>
            <div class="flex">
                <ion-icon
                    slot="start"
                    name="mail"
                    class="fa-line-padding"
                ></ion-icon>
                <a
                    href="mailto:{{
                        (toolService.content | async)?.basic?.email?.coachMia
                    }}"
                    class="link-anchor"
                    >{{
                        (toolService.content | async)?.basic?.email?.coachMia
                    }}</a
                >
            </div>
        </div>
    </div>
    <!-- Kelli -->
    <div class="no-color-container" *ngIf="amo?.kelli">
        <ion-item>
            <ion-avatar slot="start" class="coach-image">
                <img src="/img/kelliCoach.png" />
            </ion-avatar>
            <ion-label>
                <div class="sidebar-subtitle bold">
                    {{
                        (toolService.content | async)?.shared?.generalSidebar
                            ?.advisor?.kelliintro
                    }}
                </div>
                <div class="text-container">
                    <ion-text color="text">
                        <div class="sidebar-text">
                            {{
                                (toolService.content | async)?.shared
                                    ?.generalSidebar?.advisor?.advisormessage
                            }}
                        </div>
                    </ion-text>
                </div>
            </ion-label>
        </ion-item>
        <div class="text-container">
            <div class="sidebar-text">Email me at:</div>
            <div class="flex">
                <ion-icon
                    slot="start"
                    name="mail"
                    class="fa-line-padding"
                ></ion-icon>
                <a
                    href="mailto:{{
                        (toolService.content | async)?.basic?.email?.coachKelli
                    }}"
                    class="link-anchor"
                    >{{
                        (toolService.content | async)?.basic?.email?.coachKelli
                    }}</a
                >
            </div>
        </div>
    </div>
    <!-- Brook -->
    <div class="no-color-container" *ngIf="amo?.brook">
        <ion-item>
            <ion-avatar slot="start" class="coach-image">
                <img src="/img/brookCoach.png" />
            </ion-avatar>
            <ion-label>
                <div class="sidebar-subtitle bold">
                    {{
                        (toolService.content | async)?.shared?.generalSidebar
                            ?.advisor?.brookintro
                    }}
                </div>
                <div class="text-container">
                    <ion-text color="text">
                        <div class="sidebar-text">
                            {{
                                (toolService.content | async)?.shared
                                    ?.generalSidebar?.advisor?.advisormessage
                            }}
                        </div>
                    </ion-text>
                </div>
            </ion-label>
        </ion-item>
        <div class="text-container">
            <div class="sidebar-text">Email me at:</div>
            <div class="flex">
                <ion-icon
                    slot="start"
                    name="mail"
                    class="fa-line-padding"
                ></ion-icon>
                <a
                    href="mailto:{{
                        (toolService.content | async)?.basic?.email?.coachBrook
                    }}"
                    class="link-anchor"
                    >{{
                        (toolService.content | async)?.basic?.email?.coachBrook
                    }}</a
                >
            </div>
        </div>
    </div>
    <!-- Megha -->
    <div class="no-color-container" *ngIf="amo?.megha">
        <ion-item>
            <ion-avatar slot="start" class="coach-image">
                <img src="/img/meghaCoach.png" />
            </ion-avatar>
            <ion-label>
                <div class="sidebar-subtitle bold">
                    {{
                        (toolService.content | async)?.shared?.generalSidebar
                            ?.advisor?.meghaintro
                    }}
                </div>
                <div class="text-container">
                    <ion-text color="text">
                        <div class="sidebar-text">
                            {{
                                (toolService.content | async)?.shared
                                    ?.generalSidebar?.advisor?.advisormessage
                            }}
                        </div>
                    </ion-text>
                </div>
            </ion-label>
        </ion-item>
        <div class="text-container">
            <div class="sidebar-text">Email me at:</div>
            <div class="flex">
                <ion-icon
                    slot="start"
                    name="mail"
                    class="fa-line-padding"
                ></ion-icon>
                <a
                    href="mailto:{{
                        (toolService.content | async)?.basic?.email?.coachMegha
                    }}"
                    class="link-anchor"
                    >{{
                        (toolService.content | async)?.basic?.email?.coachMegha
                    }}</a
                >
            </div>
        </div>
    </div>
    <!-- Justin -->
    <div class="no-color-container" *ngIf="amo?.justin">
        <ion-item>
            <ion-avatar slot="start" class="coach-image">
                <img src="/img/justinCoach.png" />
            </ion-avatar>
            <ion-label>
                <div class="sidebar-subtitle bold">
                    {{
                        (toolService.content | async)?.shared?.generalSidebar
                            ?.advisor?.justinintro
                    }}
                </div>
                <div class="text-container">
                    <ion-text color="text">
                        <div class="sidebar-text">
                            {{
                                (toolService.content | async)?.shared
                                    ?.generalSidebar?.advisor?.advisormessage
                            }}
                        </div>
                    </ion-text>
                </div>
            </ion-label>
        </ion-item>
        <div class="text-container">
            <div class="sidebar-text">Email me at:</div>
            <div class="flex">
                <ion-icon
                    slot="start"
                    name="mail"
                    class="fa-line-padding"
                ></ion-icon>
                <a
                    href="mailto:{{
                        (toolService.content | async)?.basic?.email?.coachJustin
                    }}"
                    class="link-anchor"
                    >{{
                        (toolService.content | async)?.basic?.email?.coachJustin
                    }}</a
                >
            </div>
        </div>
    </div>
    
    
    <!-- Chris -->
    <div class="no-color-container" *ngIf="amo?.chris">
        <ion-item>
            <ion-avatar slot="start" class="coach-image">
                <img src="/img/chrisCoach.png" />
            </ion-avatar>
            <ion-label>
                <div class="sidebar-subtitle bold">
                    {{
                        (toolService.content | async)?.shared?.generalSidebar
                            ?.advisor?.chrisintro
                    }}
                </div>
                <div class="text-container">
                    <ion-text color="text">
                        <div class="sidebar-text">
                            {{
                                (toolService.content | async)?.shared
                                    ?.generalSidebar?.advisor?.advisormessage
                            }}
                        </div>
                    </ion-text>
                </div>
            </ion-label>
        </ion-item>
        <div class="text-container">
            <div class="sidebar-text">Email me at:</div>
            <div class="flex">
                <ion-icon
                    slot="start"
                    name="mail"
                    class="fa-line-padding"
                ></ion-icon>
                <a
                    href="mailto:{{
                        (toolService.content | async)?.basic?.email?.coachChris
                    }}"
                    class="link-anchor"
                    >{{
                        (toolService.content | async)?.basic?.email?.coachChris
                    }}</a
                >
            </div>
        </div>
    </div>
    
     <!-- Grace -->
    <div class="no-color-container" *ngIf="amo?.grace">
        <ion-item>
            <ion-avatar slot="start" class="coach-image">
                <img src="/img/graceCoach.png" />
            </ion-avatar>
            <ion-label>
                <div class="sidebar-subtitle bold">
                    {{
                        (toolService.content | async)?.shared?.generalSidebar
                            ?.advisor?.graceintro
                    }}
                </div>
                <div class="text-container">
                    <ion-text color="text">
                        <div class="sidebar-text">
                            {{
                                (toolService.content | async)?.shared
                                    ?.generalSidebar?.advisor?.advisormessage
                            }}
                        </div>
                    </ion-text>
                </div>
            </ion-label>
        </ion-item>
        <div class="text-container">
            <div class="sidebar-text">Email me at:</div>
            <div class="flex">
                <ion-icon
                    slot="start"
                    name="mail"
                    class="fa-line-padding"
                ></ion-icon>
                <a
                    href="mailto:{{
                        (toolService.content | async)?.basic?.email?.coachGrace
                    }}"
                    class="link-anchor"
                    >{{
                        (toolService.content | async)?.basic?.email?.coachGrace
                    }}</a
                >
            </div>
        </div>
    </div>
</div>
