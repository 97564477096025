import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToolService } from '@app/core/services/tool/tool.service';
import { isNil } from '@app/shared/utils';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '@app/core/services/modal/modal.service';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { GoogleMap, GoogleMapsModule, MapAdvancedMarker, MapInfoWindow } from '@angular/google-maps';

@Component({
    selector: 'app-program-summary',
    templateUrl: './application-program-summary.component.html',
    styleUrls: ['./application-program-summary.component.scss'],
})
export class ApplicationSummaryComponent implements OnInit, AfterViewInit {
    @ViewChild('locationMap', { static: true }) mapElement: GoogleMap;
    @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

    @Input() program?: any = {};
    @Input() application?: any;
    @Input() user?: any;

    map: any;
    content: any;
    summary: any = {
        affiliated: false,
    };
    faMapPin = faMapPin;

    modaldata: any = {
        class: 'sidebar-modal-md',
        bottomMessage: '',
        header: '',
        title: '',
    };

    showTeleMedicine: boolean = false;
    showPreMed: boolean = false;
    showFlexible: boolean = false;

    mapOptions: google.maps.MapOptions;

    primaryCoordinate: any;
    affiliatedCoordinates: any[];

    boundsNew: any;

    constructor(private tools: ToolService, private modalService: ModalService, private angulartics2GoogleTagManager: Angulartics2GoogleTagManager) {
        this.tools.content.subscribe((text: any) => (this.content = text));
    }

    // onInit needed to declare vars needed by google-map component prior to rendering
    ngOnInit() {
        if (this.program !== undefined && this.program !== null) {
            if (Object.keys(this.program).length > 0 && this.program.constructor === Object) {
                this.showTeleMedicineModal();
                this.showPreMedContent();
                this.showFlexibleContent();
                let queriedAffiliatedSites =
                    typeof this.program.affiliatedsites === 'string' ? JSON.parse(this.program.affiliatedsites) : this.program.affiliatedsites;
                const affiliated = queriedAffiliatedSites.filter((g: any) => !isNil(g.name) && g.reporting == true);
                let cord = this.program.geoloc;
                if (!Array.isArray(cord)) {
                    cord = this.program.geoloc.split(',');
                }
                const latLng = new google.maps.LatLng(cord[0], cord[1]);

                this.boundsNew = new google.maps.LatLngBounds();
                this.boundsNew.extend(latLng);

                this.mapOptions = {
                    center: { lat: latLng.lat(), lng: latLng.lng() },
                    zoom: 4,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    mapId: `program-${this.program.id}-locations-map`,
                };

                const imgTag = document.createElement('img');
                imgTag.src = '/img/orange_pin_1.png';

                this.primaryCoordinate = {
                    position: { lat: latLng.lat(), lng: latLng.lng() },
                    title: this.program.name,
                    icon: imgTag,
                };

                if (affiliated && affiliated.length > 0) {
                    this.affiliatedCoordinates = affiliated.map((affiliatedLocation: any) => {
                        if (affiliatedLocation.geoloc) {
                            const _position = affiliatedLocation.geoloc.split(',');
                            const _coordinates = new google.maps.LatLng(_position[0], _position[1]);
                            this.boundsNew.extend(_coordinates);
                            const imgTag = document.createElement('img');
                            imgTag.src = '/img/blue_pin_1.png';
                            return { position: { lat: _coordinates.lat(), lng: _coordinates.lng() }, icon: imgTag, title: affiliatedLocation.name };
                        }
                    });
                } else {
                    this.summary.affiliated = false;
                }
            }
        }
    }

    // can access googleMap directive after rendering
    ngAfterViewInit() {
        if (this.affiliatedCoordinates.length > 0) {
            this.mapElement.googleMap.fitBounds(this.boundsNew);
        }
    }

    onMarkerClick(marker: MapAdvancedMarker) {
        marker.advancedMarker.classList.add('bounce');
        this.infoWindow.open(marker, false, `<h4>${marker.advancedMarker.title}<h4>`);
    }

    openTeleMedicineModal() {
        this.angulartics2GoogleTagManager.pageTrack('/teleMedicineCta');
        this.angulartics2GoogleTagManager.eventTrack('teleMedicineCta', {
            category: 'Profile Block',
        });

        this.modaldata.title = `${this.content.shared.telemedicine.title}`;
        this.modaldata.topMessage = '';
        this.modaldata.bottomMessage = `${this.content.shared.telemedicine.bottomMessage}`;
        this.modalService.promotionModal(this.modaldata).then((response: any) => {});
    }

    showTeleMedicineModal() {
        if (this.program.programtags.includes('COVID-Telemed')) {
            this.showTeleMedicine = true;
            return true;
        } else {
            this.showTeleMedicine = false;
            return false;
        }
    }

    showPreMedContent() {
        if (this.user) {
            if (this.program.type.includes('Pre-Med Shadowing') && this.user.status === 'Pre-Med') {
                this.showPreMed = true;
                return true;
            } else {
                this.showPreMed = false;
                return false;
            }
        } else {
            this.showPreMed = false;
            return false;
        }
    }

    showFlexibleContent() {
        if (this.program.programtags.includes('Flexible')) {
            this.showFlexible = true;
            return true;
        } else {
            this.showFlexible = false;
            return false;
        }
    }
}
