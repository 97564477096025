import { Component, Input, OnInit } from '@angular/core';
import {
  faCheckCircle,
  } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-successful-action',
  templateUrl: './successful-action.component.html',
  styleUrls: ['./successful-action.component.scss'],
})
export class SuccessfulActionComponent implements OnInit {

  @Input() message: any;
  @Input() submessage: any;

  faCheckCircle = faCheckCircle;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  // manual close
  close() {
    try {
      this.modalCtrl.dismiss();
    } catch (e) {
      // failed to dismiss probably already happened
    }
  }

}
