import { Component, Input, OnInit} from '@angular/core';
import { State } from '@app/core/ngrx/reducers';
import { applicationSelectors } from '@core/ngrx/selectors';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-upcoming-deadline',
  templateUrl: './upcoming-deadline.component.html',
  styleUrls: ['./upcoming-deadline.component.scss'],
})
export class SidebarUpcomingDeadlineComponent implements OnInit {

  @Input() name: string;

  date$: Observable<any>;
  remain$: Observable<number>;
  message$: Observable<string>;



  constructor(
    private store: Store<State>
  ) {
    this.date$ = this.store.pipe(select(applicationSelectors.getDeadline));
    this.remain$ = this.store.pipe(select(applicationSelectors.getRemaining));
    this.message$ = this.store.pipe(select(applicationSelectors.getDeadlineMessage));
     }

  /* Reminders per deadline date prior to program start date:

  45-day reminder:
  "The deadline to enroll is 45 days before your scheduled start date.
  Failure to complete your payment balance before the deadline will result in a $200 late fee."

  30-day reminder:
  "Please complete your required documents as soon as possible in order
  to avoid any complications in your travel arrangements or with the start of your rotation."

  15-day reminder:
  "Please complete required documents as soon as possible. Failure to do so may result in your program being delayed or cancelled."

  */

  ngOnInit() {}

}
