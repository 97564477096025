import { Component, OnInit } from '@angular/core';
import { ToolService } from '@app/core/services/tool/tool.service';
import { ModalService } from '@core/services/modal/modal.service';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-help-sidebar',
  templateUrl: './help-sidebar.component.html',
  styleUrls: ['./help-sidebar.component.scss'],
})
export class SidebarHelpComponent implements OnInit {
  data = {
    title: '',
    header: '',
    icon: '',
    item1: '',
    item2: '',
    item3: '',
    item4: '',
    color: '',
    class: 'sidebar-modal-xs',
  };
  textcontent: any;

  constructor(
    private toolService: ToolService,
    private modalService: ModalService
  ) {
    this.toolService.content.subscribe((textcontent: any) => this.textcontent = textcontent);
  }

  ngOnInit() {}

  openInformationModal() {
    this.data.item1 = this.textcontent.basic.email.main;
    this.data.item2 = this.textcontent.basic.phone.main;
    this.data.item3 = 'Read our FAQ.';
    this.data.item4 = 'Chat with us!';
    this.modalService.basicHelpAlert(this.data).then((response: any) => {
    });
  }

}
