import { Component, Input, OnInit } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-successful-payment',
    templateUrl: './payment-successful-charge.component.html',
    styleUrls: ['./payment-successful-charge.component.scss'],
})
export class PaymentSuccessfulChargeComponent implements OnInit {
    constructor(private modalCtrl: ModalController) {}

    @Input() type: string;

    faCheckCircle = faCheckCircle;

    ngOnInit() {}

    close() {
        this.modalCtrl.dismiss();
    }
}
